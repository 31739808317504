<template>
  <div class="communication-thread-chat">
    <div class="chat-messages">
      <div
        v-if="hasPrevious"
        class="load-previous ui-clickable"
        @click="fetchPrevious"
      >Cargar anteriores</div>

      <ui-chat-bubble
        v-for="(message,i) in messages"
        :key="i"
        v-bind="message"
        class="thread-bubble"
        :id="`msg-bubble-${message.id}`"
      >
        <template #options>
          <ui-icon
            v-if="!message.isMe"
            class="ui-clickable icon-reply icon-option"
            value="mdi:reply"
            @click="openReplyTo(message.id)"
          ></ui-icon>
          <ui-icon
            v-if="message.isMe"
            class="ui-clickable icon-delete icon-option"
            value="mdi:close"
            @click="deletePost(message.id)"
          ></ui-icon>
        </template>
      </ui-chat-bubble>
    </div>

    <div class="chat-input">
      <div
        class="reply-to"
        v-if="msgReplyTo"
      >
        <ui-chat-bubble
          :show-avatar="false"
          :show-date="false"
          v-bind="msgReplyTo"
        >
          <template #options>
            <ui-icon
              class="ui-clickable icon-cancel-reply icon-option"
              value="mdi:close"
              @click="closeReplyTo"
            ></ui-icon>
          </template>
        </ui-chat-bubble>
      </div>

      <input
        type="text"
        class="ui-native chat-input-field"
        v-model="currentText"
        @keydown.enter="addPost"
        @input="onTextInput"
      />
    </div>
    <span
      class="ui-label chat-typing-notice"
      v-text="typingNotice"
    ></span>
  </div>
</template>

<script>
import useIo from '@/app/mixins/useIo';
import useApi from '@/modules/api/mixins/useApi';
import apiCommunication from '@/modules/communication/api';

import { UiIcon, UiChatBubble } from '@/modules/ui/components';

export default {
  name: 'communication-thread-chat',
  mixins: [useIo, useApi],
  $api: apiCommunication,
  components: { UiIcon, UiChatBubble },

  props: {
    threadId: {
      type: String,
      required: true
    },

    personId: {
      type: String,
      required: true
    },

    limit: {
      type: [String, Number],
      required: false,
      default: 30
    }
  },

  data() {
    return {
      posts: [],
      currentText: '',
      replyToId: null,
      hasPrevious: true,

      typists: [],
      typingTimeout: null,
      isTyping: false
    };
  },

  watch: {
    personId: {
      handler() {
        this.getPosts();
      }
    },

    threadId: {
      handler() {
        this.getPosts();
      }
    }
  },

  mounted() {
    this.getPosts().then(() => setTimeout(this.scrollToBottom, 200));
  },

  computed: {
    messages() {
      return this.posts
        .filter(post => !!post.body)
        .map(post => ({
          id: post.id,
          person: post.author,
          date: post.datePublished,
          text: post.body,
          isMe: post.author.id == this.personId,
          replyTo:
            post.replyTo && post.replyTo.id
              ? {
                  id: post.replyTo.id,
                  person: post.replyTo.author,
                  date: post.replyTo.datePublished,
                  text: post.replyTo.body
                }
              : null
        }))
        .reverse();
    },

    msgReplyTo() {
      if (!this.replyToId) {
        return null;
      }

      let post = this.posts.find(p => p.id == this.replyToId);
      if (!post) {
        return null;
      }

      return {
        id: post.id,
        person: post.author,
        date: post.datePublished,
        text: post.body
      };
    },

    typingNotice() {
      if (!this.typists.length) {
        return '';
      }

      if (this.typists.length == 1) {
        return this.typists[0].firstName + ' está escribiendo';
      }

      if (this.typists.length <= 3) {
        let names = this.typists.map(p => p.firstName);
        return (
          names.slice(0, names.length - 1).join(', ') +
          ' y ' +
          names[names.length - 1] +
          ' están escribiendo'
        );
      }

      return this.typists.length + ' personas escribiendo';
    }
  },

  $io: {
    room() {
      return `thread-${this.threadId}`;
    },

    events: {
      message(event) {
        this.posts.unshift(event.data);
        this.scrollToBottom();
      },

      deleteMessage(event) {
        let index = this.posts.findIndex(p => p.id == event.data);
        if (index !== -1) {
          this.posts.splice(index, 1);
        }
      },

      startTyping(event) {
        let foundIndex = this.typists.findIndex(p => p.id == event.author.id);
        if (foundIndex == -1) {
          this.typists.push(event.author);
        }
      },

      stopTyping(event) {
        let foundIndex = this.typists.findIndex(p => p.id == event.author.id);
        if (foundIndex >= 0) {
          this.typists.splice(foundIndex, 1);
        }
      }
    }
  },

  methods: {
    openReplyTo(postId) {
      this.replyToId = postId;
      this.$el.querySelector('.chat-input-field').focus();
    },

    closeReplyTo() {
      this.replyToId = null;
    },

    async getPosts() {
      this.posts = await this.$api.getThreadPosts(
        this.threadId,
        this.personId,
        { limit: this.limit }
      );

      this.hasPrevious = this.posts.length == this.limit;
      this.scrollToBottom();
    },

    async fetchPrevious() {
      let lastPost = this.posts[this.posts.length - 1];
      if (!lastPost) {
        return;
      }

      let incoming = await this.$api.getThreadPosts(
        this.threadId,
        this.personId,
        { limit: this.limit, before: lastPost.id }
      );
      this.hasPrevious = incoming.length == this.limit;
      this.posts = this.posts.concat(incoming);

      // "Conservar" la posicion de scroll de .chat-messages
      this.$nextTick(() => {
        this.$el.querySelector('.chat-messages').scrollTop =
          this.$el.querySelector(`#msg-bubble-${lastPost.id}`).offsetTop - 70;
      });
    },

    async addPost() {
      if (!this.currentText.trim()) {
        return;
      }

      let incomingPost = await this.$api.createThreadPost(
        this.threadId,
        this.personId,
        {
          body: this.currentText,
          replyTo: this.replyToId
        }
      );

      if (this.replyToId) {
        incomingPost.replyTo = this.posts.find(p => p.id == this.replyToId);
        this.closeReplyTo();
      }

      this.currentText = '';
      this.posts.unshift(incomingPost);
      this.scrollToBottom();

      this.$io.broadcast('message', incomingPost);
    },

    async deletePost(postId) {
      if (!confirm('Eliminar este mensaje?')) {
        return;
      }

      await this.$api.deletePost(postId);
      let index = this.posts.findIndex(p => p.id == postId);
      if (index !== -1) {
        this.posts.splice(index, 1);
      }

      this.$io.broadcast('deleteMessage', postId);
    },

    onTextInput() {
      if (!this.currentText.trim()) {
        return;
      }

      clearTimeout(this.typingTimeout);

      if (!this.isTyping) {
        this.$io.broadcast('startTyping');
      }

      this.isTyping = true;
      this.typingTimeout = setTimeout(() => {
        this.isTyping = false;
        this.$io.broadcast('stopTyping');
      }, 2000);
    },

    scrollToBottom() {
      this.$nextTick(() =>
        this.$el.querySelector('.chat-messages').scrollTo(0, 10000)
      );
    }
  }
};
</script>

<style lang="scss">
.communication-thread-chat {
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;

  .load-previous {
    margin: 8px;
    border-radius: var(--ui-radius);
    background-color: #eee;
    text-align: center;

    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: bold;
    padding: var(--ui-padding);
  }

  .chat-messages {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }

  .thread-bubble {
    margin: 22px 0;
  }

  .icon-option {
    width: 42px;
    height: 42px;
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.3);

    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .icon-cancel-reply:hover,
  .icon-delete:hover {
    color: var(--ui-color-danger);
  }

  .chat-input {
    padding: 0 8px;

    .ui-native {
      width: 100%;
      margin: auto;
      border-radius: var(--ui-radius);
      outline: none;
    }
  }

  .reply-to {
    border-left: 5px solid var(--ui-color-primary);
    border-radius: var(--ui-radius);
    padding: var(--ui-padding);
    margin: 6px;
  }

  .chat-typing-notice {
    padding: 4px 12px;
    text-align: right;
    min-height: 2.5em;
    font-weight: 500;
  }
}
</style>